<template lang="pug">
.content
  section.container-fluid.p-t-20
    a-breadcrumb(separator=">")
      a-breadcrumb-item
        router-link(to="/") 控制台
      a-breadcrumb-item
        router-link(to="/post") 动态管理
      a-breadcrumb-item {{ data.id ? '编辑动态' : '新增动态' }}
      
    .m-t-15.m-b-20
      h1.section-title.inline {{ data.id ? '编辑动态' : '新增动态' }}
      a-button.m-l-15(size="small" @click="() => { this.$router.push({ name: 'Post Management' }) }") 动态管理
      
  .container-fluid.clearfix(style="padding-right: 15px; margin-right: 335px;")
    .inner-content-right
      .editor-wrap
        a-card.m-b-15(size="small" :bordered="false" :bodyStyle="{ padding: 0 }")
          a-input(size="large" v-model="data.title")
          
        a-card.m-b-15(size="small" :bodyStyle="{ padding: 0 }")
          vc-tinymce(height="460" v-model="data.content")
          
        a-card.m-b-15(size="small" title="描述:")
          a-textarea(v-model="data.excerpt")
            
    .secondary-sidebar-right
      a-card.m-b-15(size="small" title="发布")
        a-form-item.m-b-0(label="状态:"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol")
          a-select.full-width(v-model="data.status")
            //- a-select-option(value="pending") 待审核
            a-select-option(value="publish") 正常
            //- a-select-option(value="private") 私密
            a-select-option(value="draft") 草稿
            //- a-select-option(value="trash") 回收站
        
        a-form-item.m-b-5(label="作者:"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol")
          a-select.full-width(v-model="data.creator_id")
            a-select-option(:value="item.id" v-for="(item, index) in users" :key="index") {{ item.display_name }}

        a-form-item.m-b-0(label="语 言:"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol")
          a-select.full-width(v-model="data.like_count")
            a-select-option(:value="0") 中文
            a-select-option(:value="1") 英文

        //- a-form-item.m-b-0(label="排 序:"
        //-   :label-col="formItemLayout.labelCol"
        //-   :wrapper-col="formItemLayout.wrapperCol")
        //-   a-input-number(v-model="data.menu_order" :min="0")
        //- a-form-item.m-b-0(label="查阅数:"
        //-   :label-col="formItemLayout.labelCol"
        //-   :wrapper-col="formItemLayout.wrapperCol")
        //-   a-input-number(v-model="data.view_count" :min="0")
        //- a-form-item.m-b-0(label="点赞数:"
        //-   :label-col="formItemLayout.labelCol"
        //-   :wrapper-col="formItemLayout.wrapperCol")
        //-   a-input-number(v-model="data.like_count" :min="0")
        //- a-form-item.m-b-0(label="评论数:"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol")
          a-input-number(v-model="data.comment_count" :min="0")
        
        a-button.submit(type="primary" block @click="submit") 提交
      
      a-card.m-b-15(size="small" title="分类")
        a-select.full-width(
          v-model="category_ids"
          placeholder="请选择分类")
          a-select-option(:value="item.id" v-for="(item, index) in post_category" :key="index") {{ item.name }}

        //- a-checkbox-group(v-model="category_ids")
        //-   ul.categories_list(v-if="post_category.length")
        //-     li(v-for="(item, index) in post_category" :key="index")
        //-       a-checkbox(:defaultChecked="category_ids.includes(item.id)" :value="item.id")
        //-         span.m-r-5(v-if="item.level")
        //-           template(v-for="i in item.level") &mdash;
        //-         span {{ item.name }}
        //-   a-empty(:image="simpleImage" v-else)

      //- a-card.m-b-15(size="small" title="标签")
      //-   a-select.full-width(
      //-     v-model="tag_ids"
      //-     mode="tags"
      //-     :disabled="tag_dis"
      //-     :loading="tag_loading"
      //-     :token-separators="[',']"
      //-     placeholder="请输入或选择标签")
      //-     a-select-option(:value="item.id" v-for="(item, index) in post_tag" :key="index") {{ item.name }}
      //-   span.hint-text.block.m-t-10 多个标签请用英文逗号(,)分开
      //-   //- a 从智能标签中选择
      //-   //- span(v-for="(item, index) in post_tag" :key="index") {{ item.name }}

      a-card.m-b-15(size="small" title="缩略图")
        small(slot="extra") {{ upload_options.thumbnail.tip }}
        //- img(:src="data.thumbnail.src" style="width:32px; height:22px;" slot="extra" v-if="data.thumbnail")
        vc-uploader(:data="data.thumbnail" :options="upload_options.thumbnail" @success="onSuccess")

      //- a-card.m-b-15(size="small" title="相册")
      //-   small(slot="extra") {{ upload_options.gallery.tip }}
      //-   vc-uploader(:data="data.gallery" :options="upload_options.gallery" @success="onSuccess")

      //- a-card.m-b-15(size="small" title="视频")
      //-   small(slot="extra") {{ upload_options.video.tip }}
      //-   vc-uploader(:data="data.video" :options="upload_options.video" @success="onSuccess")

      //- pre(style="font-size:10px;") {{ post_tag }}
      //- pre(style="font-size:10px;") {{ tag_ids }}

</template>

<script>
//- import { mapState } from 'vuex'

import { Empty } from 'ant-design-vue';

import vcUploader from '@/components/vcUploader'
import vcTinyMCE from '@/components/vcTinyMCE'

export default {
  name: 'PostForm',
  components: {
    'vc-uploader': vcUploader,
    'vc-tinymce': vcTinyMCE
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    rules: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      tag_dis: false,
      tag_loading: false,
      
      activeKey: [],   // collapse

      formItemLayout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },

      users: [],
      post_category: [],
      post_tag: [],
      replaceFields: {
        children:'children',
        title: 'name',
        key: 'id',
        value: 'id'
      },
      
      upload_options: {
        thumbnail: {
          accept: ['.jpg', '.gif', 'image/webp'],
          multiple: false,
          app: 'admin',
          module: 'post',
          key: 'thumbnail',
          tip: '尺寸大小建议 420 × 264 像素'
        },
        gallery: {
          multiple: true,
          app: 'admin',
          module: 'post',
          key: 'gallery',
          tip: '尺寸大小建议 640 × 641 像素'
        },
        video: {
          multiple: true,
          app: 'admin',
          module: 'post',
          key: 'video',
          tip: '尺寸大小建议 640 × 641 像素'
        },
      },
    }
  },
  computed: {
    category_ids: {
      get() {
        if (!this.data.categories) return [];
        return this.data.categories.map(item => item.id);
      },
      set(val) {
        const items = this.post_category.filter(item => val.includes(item.id));
        this.data.categories = items;
      }
    },
    tag_ids: {
      get() {
        if (!this.data.tags) return [];
        return this.data.tags.map(item => item.id);
      },
      /*
       * @param {[uuid/name]} val
       */
      async set(val) {
        const new_tags = [];
        let selected = [];
        
        const tag_ids = this.post_tag.map(item => item.id);
        const tag_names = this.post_tag.map(item => item.name);
        
        val.map(v => {
          if (tag_ids.includes(v)) {
            selected.push(this.post_tag.find(item => item.id === v));
          } else if (tag_names.includes(v)) {
            selected.push(this.post_tag.find(item => item.name === v));
          } else {
            new_tags.push(v);
          }
        });
        
        if (new_tags.length) {
          try {
            this.tag_loading = true;
            this.tag_dis = true;

            const res = await Promise.all(new_tags.map(async name => {
              return await this.$http.post('post_tag', { name });
            }));
            
            this.tag_loading = false;
            this.tag_dis = false;

            const res_tags = res.map(item => {
              return {
                id: item.id,
                name: item.name
              }
            });

            this.post_tag = this.post_tag.concat(res_tags);
            selected = selected.concat(res_tags);
          } catch(err) {
            this.tag_loading = false;
            this.tag_dis = false;
          }
        }
        
        // 去重
        const tags = [];
        selected.map(item => {
          if (!tags.includes(item)) tags.push(item)
        });

        this.data.tags = tags;

        //- 3C数码,露西,杰克
      }
    }
  },
  methods: {
    //- 创建标签
    async createTag() {
    },
    //- 获取父级
    async getParent() {},
    //- 获取分类标签
    async getTaxonomies(taxonomy) {
      this[taxonomy] = await this.$http.get(taxonomy, {
        params: {
          limit: -1
        }
      });
    },
    //- 获取用户
    async getUsers() {
      this.users = await this.$http.get('user', {
        params: {
          limit: -1
        }
      });
    },
    async submit() {
      try {
        const method = this.data.id ? 'put' : 'post';
        const url = this.data.id ? `post/${this.data.id}` : 'post';
        await this.$http[method](url, this.data);
        // this.$refs.form.resetFields();
        this.$message.success('提交成功');
      } catch(err) {
          this.loading = false;
          this.$message.error(err.message);
        }
    },

    /*********************************************************
     * @description 上传组件回调 删除/上传成功
     *********************************************************/
    // 删除/上传成功
    onSuccess(key, res) {
      this.data[key] = res;
    },
  },
  created() {
    //- this.getParent();
    this.getTaxonomies('post_category');
    this.getTaxonomies('post_tag');
    this.getUsers();
  },
  mounted() {
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
}
</script>

<style lang="less" scoped>
.secondary-sidebar-right {
  float: left;
  width: 310px;
  margin-right: -310px;
}
.inner-content-right {
  float: left;
  width: 100%;
  margin-right: 15px;
}
</style>